import * as React from 'react'
import Layout from 'components/Layout/Layout'
import Seo from 'components/Layout/Seo'
import { DataPrivacySlice } from 'components/Slices'

const DocumentationPage = () => (
  <Layout lightHeader>
    <Seo title="Data Privacy" pathname="data-privacy" />
    <DataPrivacySlice />
  </Layout>
)

export default DocumentationPage
